import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import room1 from "../../content/rooms/room-1.yml"
import room2 from "../../content/rooms/room-2.yml"
import room3 from "../../content/rooms/room-3.yml"
import room4 from "../../content/rooms/room-4.yml"
import room5 from "../../content/rooms/room-5.yml"
import logo from '../images/logo.png'
import bg from '../images/bg.jpg'
import glyph_1 from '../images/glyph_1.png'
import glyph_2 from '../images/glyph_2.png'
import glyph_3 from '../images/glyph_3.png'
import glyph_4 from '../images/glyph_4.png'
import glyph from '../images/glyph.png'
import sprite from '../images/sprite.png'
import Div100vh from 'react-div-100vh';

class IndexPage extends React.Component {
  state = {
    loading: true
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({loading: false})
    },2000)
    if(this.props.location.search.length > 0) {
      this.setState({loading: false})
    }
  }
  render() {
    
    return (
      <Layout location={this.props.location} color={'#fff'} bg={bg} >
        <SEO
          title="Home"
        />
        <Div100vh>
        <div className="landing" >
          <div className="logo-holder">
            <img className="logo" src={logo} alt=""/>
            <div className="links">
              <AniLink fade duration={1} className="link" to="/room-3">
                <img style={{width: '30px'}} src={glyph_3} alt=""/>
                <div>{room3.title}</div>
              </AniLink>
              <AniLink fade duration={1} className="link" to="/room-1">
                <img src={glyph_1} alt=""/>
                <div>{room1.title}</div>
              </AniLink>
              <AniLink fade duration={1} className="link" to="/room-2">
                <img style={{paddingBottom: '0.5rem'}} src={glyph_2} alt=""/>
                <div>{room2.title}</div>
              </AniLink>
              <AniLink fade duration={1} className="link" to="/room-4">
                <img style={{width: '40px'}} src={glyph_4} alt=""/>
                <div>{room4.title}</div>
              </AniLink>
              <AniLink fade duration={1} className="link" to="/room-5">
                <img style={{width: '40px'}} src={glyph} alt=""/>
                <div>{room5.title}</div>
              </AniLink>
            </div>
          </div>
        </div>
        </Div100vh>
        <div className="loader-holder" style={{opacity: this.state.loading ? 1 : 0}}>
          <div class="loader" style={{backgroundImage: `url(${sprite})`}}>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
